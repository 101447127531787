<template>
  <div class="q-pa-md">
    <q-table
      title="Projects"
      @row-click="projectClickEvt"
      :rows="projects"
      :columns="columns"
      :filter="filter"
      :dense="true"
      :pagination="{ rowsPerPage: 15 }"
      @row-contextmenu="projectRightClickEvt"
    >
      <template v-slot:top-right>
        <q-input borderless dense debounce="300" v-model="filter" outlined placeholder="Search">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
        <div class="q-pl-md">
          <q-btn-dropdown color="primary" label="Actions">
            <q-list>
              <q-item clickable v-close-popup @click="showDialog(0)">
                <q-item-section>
                  <q-item-label>Add</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="number" :props="props">
            {{ props.row.number }}
          </q-td>
          <q-td key="name" :props="props">
            {{ props.row.name }}
          </q-td>
          <q-td key="location" :props="props">
            {{ props.row.location }}
          </q-td>
          <q-td key="client" :props="props">
            {{ props.row.client }}
          </q-td>
          <q-td key="noticeToProceed" :props="props">
            {{ `${new Intl.DateTimeFormat('en-US').format(new Date(props.row.noticeToProceed))}` }}
          </q-td>
          <q-td key="contractStartDate" :props="props">
            {{ `${new Intl.DateTimeFormat('en-US').format(new Date(props.row.contractStartDate))}` }}
          </q-td>
          <q-td key="contractEndDate" :props="props">
            {{ `${new Intl.DateTimeFormat('en-US').format(new Date(props.row.contractEndDate))}` }}
          </q-td>
          <q-td key="actions" :props="props">
            <q-btn class="q-mr-sm" color="white" text-color="black" label="View" size="sm" @click="projetView(props.row.id)"/>
            <q-btn color="white" text-color="black" label="Edit" size="sm" @click="showDialog(props.row.id)"/>
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <q-dialog
      v-model="projectDialog"
    >
      <q-card style="width: 800px; max-width: 80vw;">
        <div class="q-pa-md">
          <q-form
            @submit="postProject()"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-4">
                <q-input
                  v-model="project.number"
                  :dense="true"
                  label="Number"
                  outlined
                />
              </div>
                <div class="col-4">
                  <q-input
                    v-model="project.name"
                    :dense="true"
                    label="Name"
                    outlined
                  />
                </div>
                <div class="col-4">
                  <q-input
                    v-model="project.location"
                    :dense="true"
                    label="Location"
                    outlined
                  />
                </div>
                <div class="col-4">
                  <q-input
                    v-model="project.client"
                    :dense="true"
                    label="Client"
                    outlined
                  />
                </div>
                <div class="col-4" />
                <div class="col-4" />
                <div class="col-4">
                  <q-input
                    v-model="project.noticeToProceed"
                    :dense="true"
                    label="Notice To Proceed Date"
                    outlined
                    mask="##/##/####"
                    hint="MM/DD/YYYY"
                  />
                </div>
                <div class="col-4">
                  <q-input
                    v-model="project.contractStartDate"
                    :dense="true"
                    label="Contract Start Date"
                    outlined
                    mask="##/##/####"
                    hint="MM/DD/YYYY"
                  />
                </div>
                <div class="col-4">
                  <q-input
                    v-model="project.contractEndDate"
                    :dense="true"
                    label="Contract End Date"
                    outlined
                    mask="##/##/####"
                    hint="MM/DD/YYYY"
                  />
                </div>
              </div>
            <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" color="primary"/>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { useQuasar } from 'quasar'
import axios from '../axios'
import router from '../router/index'

export default {
  components: [
    axios
  ],
  data() {
    return {
      notify: useQuasar(),
      projects: [],
      project: {},
      projectDialog: false,
      submitDisable: false,
      filter: '',
      columns: [
        { name: 'number', align: 'left', label: 'Number', field: 'number', sortable: true },
        { name: 'name', label: 'Name', field: 'name', sortable: true },
        { name: 'location', label: 'Location', field: 'location', sortable: true },
        { name: 'client', label: 'Client', field: 'client', sortable: true },
        {
          name: 'noticeToProceed',
          label: 'Notice To Proceed',
          field: 'noticeToProceed',
          sortable: true
        },
        {
          name: 'contractStartDate',
          label: 'Contract Start Date',
          field: 'contractStartDate',
          sortable: true
        },
        {
          name: 'contractEndDate',
          label: 'Contract End Date',
          field: 'contractEndDate',
          sortable: true
        },
        {
          name: 'actions',
          label: 'Actions'
        }
      ]
    }
  },
  created() {
    this.getProjects()
  },
  methods: {
    goto(route) {
      router.push(route)
    },
    async getProjects() {
      axios.get('/Projects')
        .then(response => {
          this.projects = response.data
        })
    },
    async getProject(id) {
      axios.get(`/Projects/Id/${id}`)
        .then(response => {
          this.project = response.data
          this.project.contractStartDate = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(new Date(this.project.contractStartDate))
          this.project.contractEndDate = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(new Date(this.project.contractEndDate))
          this.project.noticeToProceed = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(new Date(this.project.noticeToProceed))

        })
    },
    async postProject() {
      const formData = new FormData()

      if (this.project.id === undefined) {
        this.project.id = 0
      }

      formData.append('id', this.project.id)
      formData.append('number', this.project.number)
      formData.append('name', this.project.name)
      formData.append('client', this.project.client)
      formData.append('location', this.project.location)
      formData.append('noticeToProceed', new Date(this.project.noticeToProceed).toJSON())
      formData.append('contractStartDate', new Date(this.project.contractStartDate).toJSON())
      formData.append('contractEndDate', new Date(this.project.contractEndDate).toJSON())

      await axios
        .post('/Projects', formData)
        .then(response => {
          this.project = response.data
          this.projectDialog = false
          this.notify.notify({
            message: 'Work saved',
            color: 'green'
          })
        })
        .catch(error => {
          this.notify.notify({
            message: error.response.data.message,
            color: 'red'
          })
        })
      await this.getProjects()
    },
    async showDialog(projectId) {
      if (projectId == 0) {
        this.project = {}
      } else {
        await this.getProject(projectId)
      }
      this.projectDialog = true
    },
    async projectClickEvt(evt, row) {
      this.goto(`/gencon/${row.id}`)
      console.log(evt)
    },
    async projetView(projectId) {
      this.goto(`/gencon/${projectId}`)
    }
  }
}
</script>
